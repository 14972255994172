class Pagination {
  $rootScope: any;
  appFactory: any;

  page:       number;
  buttons:    string[];

  totalCount:   number;
  callback:     Function;
  constructor($scope, $rootScope, AppFactory) {
    Object.defineProperties(this, {
      $scope:     { value: $scope     },
      $rootScope: { value: $rootScope },
      appFactory: { value: AppFactory },
    });

    $scope.$watch('$ctrl.totalCount', (newVal, oldVal) => {
      if (newVal && (newVal !== oldVal || !this.buttons)) this.buttons = this.getPaginationButtons();
    }, true);
    $scope.$watch('$ctrl.page', (newVal, oldVal) => {
      if (newVal && (newVal !== oldVal || !this.buttons)) this.buttons = this.getPaginationButtons();
    }, true);
  }

  pagination(page, index = null): void {
    if (this.page !== page) {
      let p = page;
      if (isNaN(page) && index !== null) {
        if (index === 0) p = Math.round(this.page / 2);
        else p = Math.round(this.page + (this.totalCount - this.page) / 2);
      }
      this.callback({ page: p });
    }
  }

  private getPaginationButtons(): string[] {
    let buttons = [];
    if (this.totalCount >= 1 && this.totalCount <= 6) for (let i = 1; i <= this.totalCount; i++) { buttons.push(i); }
    else if (this.totalCount > 6) {
      if (this.page <= 4) for (let i = 1; i <= 5; i++) { buttons.push(i); }
      else if (this.page > 4) buttons.push('...');
      if (this.page > 4 && this.page + 3 < this.totalCount) {
        buttons.push(this.page - 1);
        buttons.push(this.page);
        buttons.push(this.page + 1);
      }
      if (this.page + 3 < this.totalCount) buttons.push('...');
      else if (this.page > 4 && (this.page + 4) > this.totalCount) for (let k = 4; k >=0; k--) { buttons.push(this.totalCount - k); }
    }
    return buttons;
  }

}

window.app.component('pagination', {
  template: require('scripts/components/pagination/pagination.html'),
  controller: ['$scope', '$rootScope', Pagination],
  bindings: {
    page:       '<',
    totalCount: '=',
    callback:   '&'
  }
});
