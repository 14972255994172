class Notifications {
  $scope:          any;
  $location:       any;
  appFactory:      any;
  databaseService: any;
  loaderFactory:   any;
  errFactory:      any;
  pnListFactory:   any;
  pnListService:   any;

  column:        string  = 'created_at';
  reverse:       boolean = false;

  page:          number  = 1;
  totalPages:    number  = 0;

  notifications: any[]   = [];

  readonly COLECTION_NAME: string = 'notifications';
  constructor($scope: any, $location: any, AppFactory: any, DatabaseService: any, LoaderFactory: any, ErrFactory: any, PNListFactory: any, PNListService: any) {
    Object.defineProperties(this, {
      $scope:          { value: $scope          },
      $location:       { value: $location       },
      appFactory:      { value: AppFactory      },
      databaseService: { value: DatabaseService },
      loaderFactory:   { value: LoaderFactory   },
      errFactory:      { value: ErrFactory      },
      pnListFactory:   { value: PNListFactory   },
      pnListService:   { value: PNListService   }
    });

    this.loadNotifications();
  }

  private loadNotifications(page = 1) {
    Promise.resolve()
    .then(() => {
      if (this.appFactory.isNetwork()) return this.refreshNotifications(page);
      else return this.loadLocalNotifications(page);
    });
  }

  sortTable(col) {
    if (col === 'created_at') {
      this.column = 'created_at';
      this.$scope.reverseDate = !this.$scope.reverseDate;
    }
    this.$scope.reverse = !this.$scope.reverse;
    this.refreshNotifications(this.page);
  }

  private refreshNotifications(page = 1) {
    this.loaderFactory.show();
    return this.pnListService.getPN(page, this.column, this.$scope.reverse)
    .then(res => {
      this.page          = page;
      this.totalPages    = res.meta.total_pages || res.meta.paging.paging.total_pages || res.meta.paging.total_pages;
      this.notifications = res.push_notifications.map(r => new this.pnListFactory(r));

      this.$scope.$apply();
      return Promise.all(this.notifications.map((data) => data.save()));
    })
    .catch((err) => console.error(err))
		.then(() => this.loaderFactory.hide());
  }

  private loadLocalNotifications(page = 1): void {
    this.loaderFactory.show();
    return this.databaseService.getLocalPagination(this.COLECTION_NAME, page, this.column, this.reverse)
    .then(res => {
      if (res) {
        this.page          = res.page;
        this.totalPages    = res.totalPages;
        this.notifications = res.sortedData.map(r => new this.pnListFactory(r));

        this.$scope.$apply();
      } else {
        this.$location.path('/projects');
        throw new this.errFactory.NoInternetConnectivity().notify();
      }
    })
    .catch(err => console.error(err))
    .then(() => this.loaderFactory.hide());
  }

}

window.app.component('pnList', {
  template: require('scripts/components/pn-list/pn-list.html'),
  controller: ['$scope', '$location', 'AppFactory', 'DatabaseService', 'LoaderFactory', 'ErrFactory', 'PNListFactory', 'PNListService', Notifications]
});
