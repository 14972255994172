(function () {
  class Button {
    label = '';
    type = 'save';
    constructor (data) {
      if (typeof data === 'string') {
        this.label = data;
      } else {
        Object.assign(this,data);
      }
    }

    toString () {
      return this.label;
    }
  }

  class Notification {
    title = '';
    desc = '';
    #buttons = [];

    constructor ($element, $timeout) {
      $timeout(() => {
        this.title = this.title;
        this.desc = this.desc;
      });
      Object.defineProperties(this,{
        $element: { value: $element }
      });
    }

    hide (ind) {
      this.$element.remove();
      this.onHide({ind});
    }

    set buttons (list) {
      this.#buttons = list.map((b) => new Button(b));
    }
    get buttons () {
      return this.#buttons;
    }
  }

  app.component('notification', {
      template: require('scripts/components/notification/notification.html'),
      controller: Notification,
      transclude: true,
      bindings: {
        title: '<',
        desc: '<',
        buttons: '<',
        onHide: '&'
      }
  });
})()
