class PinchZoomImage {
  constructor () {
    this.MIN_SCALE = 1;
    this.MAX_SCALE = 16;

    this.imgWidth       = null;
    this.imgHeight      = null;

    this.viewportWidth  = null;
    this.viewportHeight = null;

    this.scale          = null;
    this.lastScale      = null;

    this.container      = null;
    this.img            = null;

    this.x     = 0;
    this.y     = 0;
    this.lastX = 0;
    this.lastY = 0;

    this.pinchCenter = null;
  }

  disableImgEventHandlers() {
    var events = ['onclick', 'onmousedown', 'onmousemove', 'onmouseout', 'onmouseover', 'onmouseup', 'ondblclick', 'onfocus', 'onblur'];

    events.forEach(event => {
      this.img[event] = () => false;
    });
  }

  absolutePosition(el) {
    let x = 0, y = 0;

    while (el !== null) {
      x += el.offsetLeft;
      y += el.offsetTop;
      el = el.offsetParent;
    }

    return { x: x, y: y };
  }

  restrictScale(scale) {
    if      (scale < this.MIN_SCALE) scale = this.MIN_SCALE;
    else if (scale > this.MAX_SCALE) scale = this.MAX_SCALE;
    return scale;
  }

  restrictRawPos(pos, viewportDim, imgDim) {
    if (pos < viewportDim / this.scale - imgDim) pos = viewportDim / this.scale - imgDim;
    else if (pos > 0) pos = 0;
    return pos;
  }

  updateLastPos(deltaX, deltaY) {
    this.lastX = this.x;
    this.lastY = this.y;
  }

  translate(deltaX, deltaY) {
    let newX = this.restrictRawPos(this.lastX + deltaX / this.scale, Math.min(this.viewportWidth, this.curWidth), this.imgWidth);
    this.x = newX;
    this.img.style.marginLeft = Math.ceil(newX * this.scale) + 'px';

    let newY = this.restrictRawPos(this.lastY + deltaY / this.scale, Math.min(this.viewportHeight, this.curHeight), this.imgHeight);
    this.y = newY;
    this.img.style.marginTop = Math.ceil(newY * this.scale) + 'px';
  }

  zoom(scaleBy) {
    this.scale = this.restrictScale(this.lastScale * scaleBy);

    this.curWidth  = this.imgWidth  * this.scale;
    this.curHeight = this.imgHeight * this.scale;

    this.img.style.width  = Math.ceil(this.curWidth)  + 'px';
    this.img.style.height = Math.ceil(this.curHeight) + 'px';

    this.translate(0, 0);
  }

  rawCenter(e) {
    let pos = this.absolutePosition(this.container);

    let scrollLeft = window.pageXOffset ? window.pageXOffset : document.body.scrollLeft;
    let scrollTop  = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;

    let zoomX = -this.x + (e.center.x - pos.x + scrollLeft) / this.scale;
    let zoomY = -this.y + (e.center.y - pos.y + scrollTop)  / this.scale;

    return { x: zoomX, y: zoomY };
  }

  updateLastScale() {
    this.lastScale = this.scale;
  }

  zoomAround(scaleBy, rawZoomX, rawZoomY, doNotUpdateLast) {
    this.zoom(scaleBy);

    let rawCenterX = -this.x + Math.min(this.viewportWidth,  this.curWidth)  / 2 / this.scale;
    let rawCenterY = -this.y + Math.min(this.viewportHeight, this.curHeight) / 2 / this.scale;

    let deltaX = (rawCenterX - rawZoomX) * this.scale;
    let deltaY = (rawCenterY - rawZoomY) * this.scale;

    this.translate(deltaX, deltaY);

    if (!doNotUpdateLast) {
      this.updateLastScale();
      this.updateLastPos();
    }
  }

  zoomCenter(scaleBy) {
    let zoomX = -this.x + Math.min(this.viewportWidth,  this.curWidth)  / 2 / this.scale;
    let zoomY = -this.y + Math.min(this.viewportHeight, this.curHeight) / 2 / this.scale;

    this.zoomAround(scaleBy, zoomX, zoomY);
  }

  zoomIn() {
    this.zoomCenter(2);
  }

  zoomOut() {
    this.zoomCenter(1/2);
  }

  resetZoom() {
    this.zoomCenter(1 / this.lastScale);
    this.x     = 0;
    this.y     = 0;
    this.lastX = 0;
    this.lastY = 0;
    this.pinchCenter = null;
    this.lastScale   = null;
  }

  onLoad() {
    this.img = document.getElementById('pinch-zoom-image-id');
    this.container = this.img.parentElement;

    this.disableImgEventHandlers();

    this.imgWidth       = this.img.width;
    this.imgHeight      = this.img.height;
    this.viewportWidth  = this.img.offsetWidth;
    this.scale          = this.viewportWidth / this.imgWidth;
    this.lastScale      = this.scale;
    this.viewportHeight = this.img.parentElement.offsetHeight;
    this.curWidth       = this.imgWidth  * this.scale;
    this.curHeight      = this.imgHeight * this.scale;

    if (!this.container.classList.contains('hammer-bind')) {
      let hammer = new Hammer(this.container, {
        domEvents: true
      });

      hammer.get('pinch').set({
        enable: true
      });

      hammer.on('pan', (e) => {
        this.translate(e.deltaX, e.deltaY);
      });

      hammer.on('panend', (e) => {
        this.updateLastPos();
      });

      hammer.on('pinch', (e) => {
        if (this.pinchCenter === null) {
          this.pinchCenter = this.rawCenter(e);
          let offsetX = this.pinchCenter.x * this.scale - (-this.x * this.scale + Math.min(this.viewportWidth,  this.curWidth)  / 2);
          let offsetY = this.pinchCenter.y * this.scale - (-this.y * this.scale + Math.min(this.viewportHeight, this.curHeight) / 2);
          this.pinchCenterOffset = { x: offsetX, y: offsetY };
        }

        let newScale = this.restrictScale(this.scale * e.scale);
        let zoomX = this.pinchCenter.x * newScale - this.pinchCenterOffset.x;
        let zoomY = this.pinchCenter.y * newScale - this.pinchCenterOffset.y;
        let zoomCenter = { x: zoomX / newScale, y: zoomY / newScale };

        this.zoomAround(e.scale, zoomCenter.x, zoomCenter.y, true);
      });

      hammer.on('pinchend', (e) => {
        this.updateLastScale();
        this.updateLastPos();
        this.pinchCenter = null;
      });

      hammer.on('doubletap', (e) => {
        let c = this.rawCenter(e);
        this.zoomAround(2, c.x, c.y);
      });
      this.container.classList.add('hammer-bind');
    }
  }

  changePage(page) {
    this.resetZoom();
    this.onPageChange({page});
  }

}

app.component('pinchZoomImage', {
  template: require('scripts/components/pinch-zoom-image/pinch-zoom-image.html'),
  controller: PinchZoomImage,
  bindings: {
    image: '=',
    currentPage: '<',
    totalPages: '<',
    onPageChange: '&'
  }
});
